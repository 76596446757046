.Footer{
  width: 100%;
  /* max-width: 988px; */
  max-width: 900px;
  margin: 10px auto auto auto;
  padding: 34px 108px 32px 108px;
  box-sizing: border-box;
  background-color: transparent;
  text-align: center;
  font-size: 1rem;
  font-family: 'Work Sans', sans-serif;
  border-top: 1px solid #cccccc;
  /* background-color: rgba(0,51,17,1); */
  color: #003311;
  /* color: #fff; */
}
.Footer > div{
  margin-bottom: 14px;
}
.FooterLink > span{
  font-size: 2em;
  margin: auto 25px;
  opacity: 0.9;
}

.FooterLink > span:hover{
  opacity: 0.6;
}

.copyrightIcon{
  font-size: 0.7rem;
  margin-right: 3px;
}

.observableIcon{
  fill: rgb(51, 51, 51);;
  transform: translateY(3px);
}

@media screen and (max-width: 500px) {
  .Footer{
    width: 95%;
    margin: 5px 10px;
    padding: 30px 0px 25px 0px;
    font-size: 0.9rem;
  }
  .copyrightIcon{
    font-size: 0.7rem;
  }
}
