.NavbarContainer{
  /* background-color: #f8f8f8; */
  /* position: sticky;
  top: 0;
  z-index: 1000; */
  /* box-shadow: 0 1px 5px rgba(0,0,0,0.28); */
}
.Navbar{
  width: 100%;
  max-width: 978px;
  /* max-width: 950px; */
  margin: auto;
  padding: 24px 0px 16px 0px;
  padding: 20px 0px 32px 0px;
  /* padding: 24px 45px 16px 45px; */
  box-sizing: border-box;
  border-bottom: 1px solid #9ca8b0;

  text-align: center;

}

.Navbar nav{
  margin: auto;
  box-sizing: content-box;
  height: 40px;
}

@media screen and (max-width: 500px) {
  .NavbarContainer{
    /* background-color: #f8f8f8; */
    position: static;
    z-index: 0;
  }
  .Navbar{
    width: 95%;
    margin: auto 10px 5px 10px;
    padding: 38px 5px 18px 5px;
    border-bottom: 1px solid #cccccc;
  }
  .Navbar nav{
    height: 60px;
  }
}
