.tourismNetwork{
  width: 96%;
  height: 800px;
  border: 1.5px solid #b3b3b3;
  border-radius: 4px;
  margin: 0px auto 10px auto;
  padding: 10px 14px;
  background-color: #fff;
}

.tourismNetworkSVG{
  width: 100%;
  height: 420px;
}

/* Header CSS */
.tourismNetworkHeader{
  height: 70px;
}
.headerTitle{
  text-align: left;
  margin: auto 10px auto auto;
  padding-left: 10px;
  padding-top: 8px;
  color: #283e3e;
  font-family: 'Ubuntu', sans-serif;
  float: left;
  width: 24%;
  height: 50px;
  vertical-align: middle;
}
.headerTitle > p{
  margin: 0;
}
.headerLine1{
  font-weight: bold;
  font-size: 1.3rem;
}
.headerLine2{
  text-align: right;
  font-weight: normal;
  font-size: 1.1rem;
}
.filterLabel{
  text-align: center;
  color: #283e3e;
  font-weight: bold;
  margin: 8px auto 2px auto;
  font-size: 1rem;
}
/* Category Filter CSS */
.categoryFilter{
  float: right;
  position: relative;
  width: 22%;
  margin: auto 10px;
  text-align: center;
}
.categoryFilter > button{
  margin-top: 4px;
  color: #fff;
  width: 100%;
  height: 26px;
  background-color: #466d6d;
  border-color: #466d6d;
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  font-size: 0.9rem;
  line-height: 1.42857143;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  word-wrap: break-word;
  opacity: 1;
}
.categoryFilter > button:hover{
  opacity: 0.9;
}
.categoryFilter > button > span{
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.open > .categoryFilterDropdownMenu{
  display: block;
}
.categoryFilterDropdownMenu{
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 100;
  width: 120%;
  height: auto;
  display: none;
  float: left;
  padding: 0;
  margin: 1px 0 0;
  font-size: 0.9rem;
  text-align: center;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
  box-shadow: 0 6px 12px rgba(0,0,0,.175);
}
.categoryFilterDropdownMenu > li{
  box-sizing: border-box;
  margin: 5px auto;
}
.categoryFilterDropdownMenu > li:hover{
  background-color: #d0e1e1;
  font-weight: bold;
}
.categoryFilterDropdownMenu > li > a{
  display: block;
  text-decoration: none;
  padding: 4px 10px;
  color: #333;
  height: 20px;
  word-wrap: break-word;
}

/* Top 5 Filter CSS */
.top5FilterContainer{
  float: right;
  width: 20%;
  min-height: 30px;
  margin: auto auto auto 12px;
  text-align: center;
}
.top5FilterGroup{
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
  width: 90%;
  margin-top: 5px;
}
.top5Filter{
  width: 48%;
  height: 24px;
  opacity: 0.9;
  color: #fff;
  background-color: #b3b3b3;
  border: none;
  font-size: 0.9rem;
}
.top5Filter:hover{
  cursor: pointer;
  opacity: 1;
}
.top5Filter.selected{
  font-weight: bold;
  opacity: 1;
  color: #fff;
  background-color: #466d6d;
  border: 1px solid #3c5d5d;
}
.top5Filter.left{
  border-radius: 5px 0px 0px 5px;
}
.top5Filter.right{
  border-radius: 0px 5px 5px 0px;
}

/* Year Filter CSS */
.yearFilter{
  float: right;
  width: 20%;
  min-height: 58px;
  margin: auto 10px;
  text-align: center;
}
/* Year filter slider CSS */
.yearFilter > input {
  transform: translateY(4px);
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  -webkit-transition: .2s;
  transition: opacity .2s;
  z-index: 2;
}
.yearFilter > input:hover{
  cursor: pointer;
}
.yearFilter > input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #466d6d;
  cursor: pointer;
}
.yearFilter > input::-moz-range-thumb {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #466d6d;
  cursor: pointer;
}
/* Labels below slider */
.yearFilter > ul {
  margin: 4px 0px 0;
  padding: 0;
  list-style: none;
  width: 103%;
  z-index: 0;
}
.yearFilter > ul  > li{
  position: relative;
  float: left;
  width: 32%;
  z-index: 0;
  text-align: center;
  color: #b2b2b2;
  font-size: 0.9rem;
  cursor: pointer;
}
.yearFilter > ul  > li::before{
  position: absolute;
  top: -12px;
  right: 0;
  left: 0;
  content: "";
  margin: 0 auto;
  width: 12px;
  height: 12px;
  background: #d3d3d3;
  border-radius: 50%;
  z-index: 0;
}
.yearFilter > ul  > li.selected{
  color: #466d6d;
  font-weight: bold;
}
.yearFilter > ul  > li.selected::before{
  display: none;
}
.yearFilter > ul  > li.firstYear{
  text-align: left;
}
.yearFilter > ul  > li.firstYear::before{
  margin: 0 auto 0 0;
}
.yearFilter > ul  > li.lastYear{
  text-align: right;
  float: right;
}
.yearFilter > ul  > li.lastYear::before{
  margin: 0 0 0 auto;
}

/* Map CSS */
.tourismMap{
  width: 100%;
  height: 400px;
}
.tourismMapLegendRect{
  opacity: 0.9;
}
.tourismMapLegendText{
  font-size: 0.7rem;
  font-weight: bold;
  color: #283e3e;
  opacity: 0.9;
}

/* Network CSS */
.nodeLegend{
  stroke-width: 2px;
  opacity: 0.9;
}
.tourismNetworkGraph{
  width: 70%;
  height: 500px;
  float: right;
  z-index: 100;
}
.node{
  stroke-width: 0px;
}

/* Table CSS */
.tourismNetworkTableContainer{
  width: 95%;
  height: 300px;
  overflow-y: scroll;
  margin: auto;
}
.tourismNetworkTable{
  width: 98%;
  text-align: center;
  font-size: 0.9rem;
}
.tourismNetworkTable > thead > tr > th{
  opacity: 0.9;
  font-family: 'Ubuntu', sans-serif;
}
.tourismNetworkTable > tbody > tr{
  opacity: 0.9;
}
.tourismNetworkTable > thead > tr > th:hover{
  text-decoration: underline;
  cursor: pointer;
}
/* Hover, click CSS */
tr.hover{
  opacity: 1;
  cursor: pointer;
  background-color: #d9d9d9;
}
path.hover{
  opacity: 0.9;
  cursor: pointer;
  stroke: #595959;
  stroke-width: 2px;
}
tr.clicked{
  opacity: 1;
  background-color: #ffa64d;
}
path.clicked{
  fill: #ff8c1a;
}

/* Scrollbar CSS */
/* width */
.tourismNetworkTableContainer::-webkit-scrollbar {
  width: 10px;
}
/* Track */
.tourismNetworkTableContainer::-webkit-scrollbar-track {
  background: #d5dddd;
  border-radius: 2px;
}
/* Handle */
.tourismNetworkTableContainer::-webkit-scrollbar-thumb {
  background: #677e7e;
  border-radius: 2px;
}
/* Handle on hover */
.tourismNetworkTableContainer::-webkit-scrollbar-thumb:hover {
  background: #677e7e;
}

/* Mobile responsive CSS */
@media screen and (max-width: 500px) {
  .tourismNetwork{
    /* width: 94%; */
    height: 1200px;
    padding: 10px 0px;
  }
  .tourismNetworkSVG{
    height: 800px;
    max-height: 600px;
  }
  /* Header CSS */
  .tourismNetworkHeader{
    /* height: 280px; */
    height: 220px;
    text-align: center;
  }
  .headerTitle{
    float: none;
    width: 94%;
    margin: auto;
    padding: 10px 4px;
    text-align: center;
    margin-bottom: 10px;
    border-bottom: 1px solid #83afaf;
  }
  .headerLine1{
    font-size: 1.4rem;
  }
  .headerLine2{
    text-align: center;
    font-size: 1.2rem;
  }
  .categoryFilter{
    float: none;
    width: 92%;
    margin: auto;
  }
  .categoryFilter > button{
    width: 80%;
  }
  .categoryFilterDropdownMenu{
    width: 100%;
  }
  .top5FilterContainer{
    float: none;
    width: 92%;
    margin: auto;
    transform: translateY(310px);
  }
  .top5FilterGroup{
    width: 80%;
  }
  .top5Filter{
    width: 50%;
  }
  .yearFilter{
    float: none;
    width: 92%;
    margin: 10px auto;
  }
  .yearFilterLabel{
    font-size: 1.1rem;
  }
  .yearFilter > input, .yearFilter > ul{
    width: 80%;
  }
  .yearFilter > ul{
    width: 82%;
    transform: translateX(12%);
  }
  .yearFilter > ul  > li{
    font-size: 1rem;
  }
  .tourismNetworkTableContainer{
    height: 370px;
  }
}
