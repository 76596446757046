.BurgerChainHeader {
  font-family: "Work Sans";
  width: 100%;
  height: auto;
  /* border-top: 1px solid #cccccc; */
  margin: auto auto 30px auto;
  text-align: left;
}
.BurgerChainHeaderTitleContainer {
  margin-top: 10px;
  height: 230px;
  color: #FFB80E;
}
.BurgerChainHeaderTitle {
  font-size: 2.5rem;
  padding-top: 7%;
  padding-left: 48%;
  margin: 0px;
  line-height: .8;
}
.BurgerChainHeaderSubTitle {
  font-size: 1.6rem;
  padding-left: 47%;
  margin: 10px;
  line-height: 1;
}
.BurgerChainHeaderTitleMobile, .BurgerChainHeaderSubTitleMobile{
  display: none;
}
.BurgerChainHeaderCategoryTitle {
  color: white;
  background-color: black;
  font-size: 1.6rem;
  padding: 1% 3%;
  margin: 8px 0px 0px 0px;
}
.BurgerChainHeaderCategorySubTitle {
  color: black;
  background-color: white;
  font-size: 1.5rem;
  padding: 0 3%;
  margin: 0px;
}

@media screen and (max-width: 800px) {
  .BurgerChainHeaderTitle {
    display: block;
    font-size: 1.9rem;
    padding-top: 8%;
    padding-left: 52%;
    margin: 0px 5px 0px 0px;
    line-height: 1.8;
  }
  .BurgerChainHeaderSubTitle {
    display: block;
    font-size: 1.4rem;
    padding-left: 51%;
    margin: 5px 10px;
    line-height: 1.2;
  }
  .BurgerChainHeaderCategoryTitle {
    font-size: 1.5rem;
  }
  .BurgerChainHeaderCategorySubTitle {
    margin-top: 4px;
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 500px) {
  .BurgerChainHeaderTitle, .BurgerChainHeaderSubTitle {
    display: none;
  }
  .BurgerChainHeaderTitleMobile{
    display: block;
    background-color: #E32929;
    color: #FFB80E;
    font-size: 1.8rem;
    padding: 5px;
    margin: 5px auto 0px auto;
  }
  .BurgerChainHeaderSubTitleMobile{
    display: block;
    background-color: #E32929;
    color: #FFB80E;
    font-size: 1.2rem;
    padding: 0px 10px 10px 10px;
    margin: auto;
  }
  .BurgerChainHeaderCategoryTitle {
    font-size: 1.3rem;
  }
  .BurgerChainHeaderCategorySubTitle {
    font-size: 1.2rem;
  }
}
