.ContentContainer{
  margin: auto;
  max-width: 950px;
  min-height: 750px;
  box-sizing: border-box;
}

.Content{
  min-height: 500px;
  width: auto;
  max-width: 950px;
  padding: 15px 45px 15px 45px;
  border-radius: 2px;
  font-family: 'Work Sans', sans-serif;
}
@media screen and (max-width: 800px) {
  .ContentContainer{
    padding: 10px 10px;
  }
  .Content{
    padding: 15px 15px 15px 20px;
  }
}
@media screen and (max-width: 500px) {
  .ContentContainer{
    padding: 10px 10px;
  }
  .Content{
    padding: 5px 15px 5px 20px;
  }
}
