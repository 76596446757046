.Back{
  margin: 10px auto 20px auto;
  font-family: 'Work Sans', sans-serif;
  transform: translateX(-5%);
}

.Back a{
  text-decoration: none;
  color: #003311;
  font-weight: bold;
  border: 1px solid #c7cdd1;
  /* color: white; */
  background-color: #eff5f5;
  border-radius: 4px;
  padding: 4px 12px 4px 6px;
}

.Back i{
  margin-right: 6px;
  font-size: 0.9rem;
}

.Back a:hover{
  background-color: #e0ebeb;
  /* font-style: italic; */
  /* text-decoration: underline; */
  /* font-weight: bold; */
}
