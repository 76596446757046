.ContentSubHeader{
  font-family: 'Work Sans', sans-serif;
  font-size: 1.4rem;
  margin: auto;
  padding: 35px 0px 4px 0px;
  font-weight: bold;
  color: #1d2f2f;
  border-bottom: 1px solid #1d2f2f;
}
@media screen and (max-width: 500px) {
  .ContentSubHeader{
    padding: 20px 0px 4px 0px;
  }
}
