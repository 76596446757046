.WorkCollaborators{
  text-align: center;
  font-family: 'Work Sans', sans-serif;
  font-size: 1.1rem;
  margin: auto;
  font-weight: bold;
  color: #1d2f2f;
}

.WorkCollaborator{
  font-weight: normal;
  text-decoration: none;
  color: #1d2f2f;
  padding-right: 2px;
}

.WorkCollaborator:hover{
  font-style: italic;
  /* text-decoration: underline; */
}
