.workHeader{
  color: #132020;
  border-bottom: 1px solid #273f3f;
  padding: 6px 0px;
}
@media screen and (max-width: 500px) {
  .workHeader{
    margin-top: 5px;
  }
}
