.ContentImageContainer{
  width: 100%;
  margin: 10px 0px 15px 0px;
  padding: 15px 5px;
}

.ContentImage{
  width: 100%;
  height: auto;
  border-radius: 2px;
}

.ContentImageDescription{
  text-align: right;
  margin: 0px 0px 0px auto;
  font-style: italic;
}
