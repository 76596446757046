.NavigationItem{
  margin: 16px 0 8px 0;
  box-sizing: border-box;
  display: block;
  line-height: 2.4;
  width: 100%;
}

.NavigationItem a{
  color: #40a4c8;
  text-decoration: none;
  font-size: 1.34rem;
  /* font-family: 'Ubuntu', sans-serif; */
  font-family: 'Work Sans', sans-serif;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
}

.NavigationItem a:hover{
  border-bottom: 2px solid #60b4d2;
}

.NavigationItemActive{
  border-bottom: 2px solid #1f935b!important;
}

@media screen and (max-width: 500px) {
  .NavigationItem{
    margin: 22px 0px 3px 0px;
    line-height: 1.3;
  }
  .NavigationItem a{
    font-size: 1.2rem;
  }
}
