.contactHeader{
  font-family: 'Work Sans', sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  color: #1d2f2f;
  text-align: center;
  width: 60%;
  margin: 20px auto 25px auto;
}
.contactFormHeader{
  min-height: 30px !important;
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.6rem;
  padding: 0px 0 20px 0;
  color: #141f1f;
}
.contactForm {
  width: 67%;
  min-height: 300px;
  height: auto;
  padding: 2px 35px;
  margin: 10px auto 30px auto;
  text-align: center;
  border-radius: 2px;
}
.contactForm input,
.contactForm textarea {
  font: inherit;
  color: rgba(0,51,17,1);
  padding: 8px;
  width: 100%;
  margin-top: 3px;
  margin-bottom: 15px;
  border: 1px solid rgba(0,51,17,0.3);
  border-radius: 2px;
  box-sizing: border-box;
}
.contactForm input:hover,
.contactForm textarea:hover {
  box-shadow: 0px 1px 2px rgba(59, 69, 64, 0.2);
}
.contactForm button {
  border: none;
  padding: 10px 22px;
  width: 24%;
  font-size: 0.95rem;
  font-weight: bold;
  background: rgba(8, 94, 51, 0.95);
  border-radius: 4px;
  color: white;
}
.contactForm button:hover {
  cursor: pointer;
  background: rgba(8, 94, 51, 1);
  font-style: italic;
}
.contactForm div {
  min-height: 80px;
  text-align: center;
  margin: 10px auto;
}
.contactForm label {
  text-align: left;
  color: #003311;
  font-weight: bold;
  font-size: 1rem;
  margin: 8px auto 4px auto;
  display: block;
}

@media screen and (max-width: 500px) {
  .contactHeader{
    width: 95%;
    line-height: 1.4;
    margin: 20px auto auto auto;
  }
  .contactForm {
    width: 95%;
    padding: 0;
    margin: 10px auto 10px auto;
  }
}
