.burgerChainContainer{
  width: 96%;
  height: 2130px;
  border: 1.5px solid #b3b3b3;
  border-radius: 4px;
  padding: 0px 10px;
  margin: 20px auto;
  background-color: #fff;
}

/* Heatmap CSS */
.burgerChainHeatmap{
  min-height: 900px;
}
.heatmapSVG{
  width: 100%;
  height: auto;
  min-height: 900px;
  transform: translateX(40px);
}
.heatmapGridLow{
  fill: #FFF9E7;
}
.heatmapGridMed{
  fill: #FFDB6B;
}
.heatmapGridHigh{
  fill: #D2A20B;
}
.heatmapGridText{
  font-size: 1.6rem;
  fill: #F0002F;
  font-family: "Trebuchet MS", Helvetica, sans-serif;
  font-weight: 600;
}

.heatmapLegendRect{
  stroke-width: 1;
  stroke: black;
}
.heatmapLegendText{
  font-family: "Trebuchet MS", Helvetica, sans-serif;
  font-weight: 300;
  font-size: 1.2rem;
  color: #202020;
}

/* Plot CSS */
.graphTitle {
  width: 100%;
  height: 341px;
}
.burgerChainPlotContainer{
  margin-top: 30px;
  float: left;
  display: flex;
  width: 100%;
  height: 550px;
}
.burgerChainPlot{
  width: auto;
  height: 500px;
}
.burgerChainPlotTooltip{
  width: auto;
  height: 500px;
}
.plotFilterText{
  font-family: "Trebuchet MS", Helvetica, sans-serif;
  fill: #202020;
}
.plotFilterText_mobile{
  display: none;
}
.plotTitle{
  font-family: "Trebuchet MS", Helvetica, sans-serif;
  font-size: 30px;
  font-weight: 900;
  fill: #CE0000;
}
.itemBoxTitle{
  font-family: "Trebuchet MS", Helvetica, sans-serif;
  font-size: 28px;
  font-weight: 900;
  fill: #005499;
}
.correlationLine{
  stroke: #b3b3b3;

}
.axisLabels{
  font-family: "Trebuchet MS", Helvetica, sans-serif;
  font-size: 22px;
  font-weight: 600;
  fill: #CE0000;
}
.itemName{
  font-weight: 900;
  font-family: "Trebuchet MS", Helvetica, sans-serif;
  font-size: 20px;
  color: #202020;
}
.itemValue{
  font-weight: 600;
  font-family: "Trebuchet MS", Helvetica, sans-serif;
  font-size: 20px;
  fill: #CE0000;
}
.itemValueSub{
  font-weight: 300;
  font-family: "Trebuchet MS", Helvetica, sans-serif;
  font-size: 20px;
}
.categoryBackdrop{
  border-radius: 2px;
  display: none;
}
.categoryName{
  font-weight: 700;
  font-family: "Trebuchet MS", Helvetica, sans-serif;
  text-anchor: middle;
  fill: #FFFFFF;
}
.hoverRestaurants{
  fill: gray;
  fill-opacity: 0;
  stroke: #595959;
  stroke-width: 0;
  stroke-dasharray: 10,10;
}
.hoverRestaurants:hover{
  cursor: pointer;
  fill-opacity: 0.3;
}
.hoverCategories{
  fill: gray;
  fill-opacity: 0;
  stroke: #595959;
  stroke-width: 0;
  stroke-dasharray: 10,10;
}
.hoverCategories:hover{
  cursor: pointer;
  fill-opacity: 0.3;
}
.clearSelection{
  opacity: 0.7;
  visibility: hidden;
}
.clearSelection:hover{
  cursor: pointer;
  opacity: 1;
}
/* Mobile Responsive-tablet CSS */
@media screen and (max-width: 800px) {
  .burgerChainContainer{
    width: 96%;
    height: 2000px;
  }
  .burgerChainHeatmap{
    min-height: 800px;
  }
  .heatmapSVG{
    min-height: 800px;
    transform: translateX(30px);
  }
  .graphTitle {
    width: 100%;
    height: 331px;
  }
}
/* Mobile Responsive-phone CSS */
@media screen and (max-width: 500px) {
  .burgerChainContainer{
    width: 100%;
    height: 1830px;
    transform: translateX(-10px);
    padding: 0px 6px;
    margin: 30px auto;
  }
  .burgerChainHeatmap{
    min-height: 400px;
  }
  .heatmapSVG{
    min-height: 400px;
    transform: translateX(30px);
  }
  .heatmapGridText{
    font-size: 1.1rem;
  }
  .heatmapLegendText{
    font-size: 0.7rem;
  }
  .graphTitle {
    width: 100%;
    height: 241px;
  }
  .burgerChainPlotContainer{
    /* float: left; */
    display: block;
    width: 380px;
    height: 700px;
  }
  .burgerChainPlot{
    display: inline-block;
    float: left;
    min-width: 380px;
    max-height: 380px;
    transform: translate(0px, 10px);
  }
  .burgerChainPlotTooltip{
    display: inline-block;
    float: left;
    min-width: 380px;
    transform: translate(-50px, -60px);

  }
  .plotTitle{
    transform: translateY(-20px);
  }
  .plotFilterText{
    display: none;
  }
  .plotFilterText_mobile{
    display: block;
  }
  .categoryBackdrop{
    display: block;
  }
  .divider{
    display: none;
  }
  .itemName{
    font-size: 16px;
    transform: translate(16%, 55px);
  }
  .itemValue{
    font-size: 16px;
    transform: translate(10%, 15px);
  }
  .itemValueSub{
    font-size: 16px;
    transform: translate(40%, -45px);
  }
}
