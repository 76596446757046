.ProjectCard{
  margin: 8px 0px 15px 0px;
  padding: 8px 20px 24px 20px;
  border: 1px solid transparent;
  border-bottom: 1px solid #cccccc;
}
.ProjectCard.last{
  border: 1px solid transparent;
}

.ProjectCard:hover{
  cursor: pointer;
  opacity: 0.9;
  border: 1px solid #4f5c63;
  border-radius: 4px;
}

.ProjectCard a{
  color:inherit;
  text-decoration: none;
  text-align: center;
  display: inline-table;
}

.ProjectCard h2, p{
  margin: 12px auto;
}

.ProjectCardTitle{
  font-family: 'Ubuntu', sans-serif;
  color: #304f4f;
  margin: 8px auto 0px auto !important;
}

.ProjectCardKeywordLabel{
  font-weight: bold;
  font-size: 1rem;
  color: #1d2f2f;
}

.ProjectCardKeywordBubble{
  padding: 3px 14px;
  margin: 2px 4px;
  border-radius: 4px;
  border: none;
  font-weight: normal;
  font-size: 0.85rem;
  color: #fff;
  /* background-color: rgba(255, 140, 26, 0.9); */
  background-color: #4daacb;
  /* background-color: #1b7e4e; */
  display: inline-block;
}

.ProjectCardDescription{
  color: #1d2f2f;
  font-family: 'Work Sans', sans-serif;
  font-size: 1rem;
  line-height: 1.9;
}

.ProjectCardImageContainer{
  text-align: center;
}
.ProjectCardImage{
  width: 90%;
  height: auto;
  border-radius: 4px;
  border: 1px solid transparent;
}

/* .ProjectCardImage:hover{
  opacity: 0.9;
  border: 1px solid #8e9ca4;
  border-radius: 4px;
} */

@media screen and (max-width: 500px) {
  .ProjectCard{
    margin: 8px 0px 15px 0px;
    padding: 8px 4px 24px 4px;
  }
  .ProjectCardTitle{
    margin: 8px auto!important;
  }
  .ProjectCard p{
    margin: 6px auto 8px auto;
  }
  .ProjectCardKeywordBubble{
    font-size: 0.8rem;
    padding: 3px 12px;
  }
}
