.aboutImageContainer{
  margin: 20px auto 30px auto;
  width: 100%;
  text-align: center;
}
.aboutImage{
  max-width: 280px;
  height: auto;
  border-radius: 220px;
}
.aboutParagraph{
  line-height: 1.9;
}

/* Mobile responsive CSS */
@media screen and (max-width: 500px) {
  .aboutImage{
    max-width: 180px;
    height: auto;
    border-radius: 220px;
  }
}
