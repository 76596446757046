.ContentHeader{
  text-align: center;
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.6rem;
  margin: 12px auto;
  padding: 18px 0 12px 0;
  color: #141f1f;
}

@media screen and (max-width: 500px) {
  .ContentHeader{
    margin-top: 10px;
    padding-top: 0px;
  }
}
