html, body{
  margin: 0;
  height: 100%;
}

body {
  margin: auto;
  font-family: "Trebuchet MS", Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Scrollbar CSS */
/* width */
body::-webkit-scrollbar {
  width: 0.8rem;
}
/* Track */
body::-webkit-scrollbar-track {
  background: #c7d1d1;
  border-radius: 0px;
}
/* Handle */
body::-webkit-scrollbar-thumb {
  background: rgba(80, 98, 98, 0.8);
  border-radius: 0px;
}
/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  background: rgba(80, 98, 98, 0.9);
}
