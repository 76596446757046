.Layout {
  /* background-color: #f8f8f8; */
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
  background: linear-gradient(176deg, rgba(245,255,250,1) 0%, rgba(248,248,248,1) 45%, rgba(255,255,255,1) 96%);
}

.Layout a:hover{

}
