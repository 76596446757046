.Paragraph{
  margin: 10px 0px 15px 0px;
  font-family: 'Work Sans', sans-serif;
  font-size: 1.06rem;
  line-height: 1.6;
  color: #1d2f2f;
}

@media screen and (max-width: 500px) {
  .Paragraph{
    font-size: 1.02rem;
    margin: 10px 0px 15px 0px;
    line-height: 1.6;
  }
}
