.ParagraphLink{
  display: inline-block;
  font-family: 'Work Sans', sans-serif;
  line-height: 1.9;
  font-weight: 600;
  text-decoration: none;
  color: #008000;
}

.ParagraphLink:hover{
  text-decoration: underline;
}

.spaceLeft{
  margin-left: 4px;
}

.spaceRight{
  margin-right: 4px;
}

@media screen and (max-width: 500px) {
  .ParagraphLink{
    line-height: 1.6;
  }
}
