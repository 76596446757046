.NavigationItems{
  padding: 0;
  margin: auto;
  width: 34%;
  list-style: none;
  display: flex;
  align-items: center;
  flex-flow: row;
  float: right;
}

@media screen and (max-width: 500px) {
  .NavigationItems{
    float: none;
    padding: 5px 0px;
    width: 100%;
  }
}
