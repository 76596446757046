.NavigationTitle{
  color: #125434;
  margin: auto;
  line-height: 2.4;
  padding-left: 18px;
  text-align: left;
  font-size: 2.1rem;
  font-family: 'Comfortaa';
  width: 30%;
  float: left;
  cursor: pointer;
}

@media screen and (max-width: 500px) {
  .NavigationTitle{
    line-height: 1;
    font-size: 2.1rem;
    line-height: 0.5;
    padding: 0px;
    display: block;
    width: 100%;
    text-align: center;
  }
}
